import React from "react";
import Img from "../img";
import shapeImage from "@/images/shape/mini.png";

const ServiceInfo = () => {
  return (
    <div className="service_style_2_area text-center section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="service_img_info_box">
              <div className="service_info_color_shape">
                <Img src={shapeImage} alt="shape" />
              </div>
            </div>
            <div className="justify-content-center d-flex">
              <p className="col-md-10 ">
                Dew Pond has been consistently delivering reliable cooling tower 
                solutions to all our clients from various sectors for the past 30 years.. 
                We offer customisable material of construction like FRP, Pultruded 
                FRP or Stainless steel cooling towers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceInfo;
