import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
// import ServiceInfo from "@/components/service-info";
// import ServiceOne from "@/components/service-one";
import ProductOne from "@/components/products/product-one";
import ProductInfo from "@/components/products/product-info";
import BenefitOne from "@/components/benefit-one";
import SponsorOne from "@/components/sponsor-one";

const Service = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Products Page">
        <HeaderOne />
        <PageHeader title="Products" name="Products" />
        <ProductInfo />
        <ProductOne />
        <BenefitOne />
        {/* <SponsorOne /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Service;
